import React, { useState, useEffect, memo } from 'react'
import { navigate } from 'gatsby'
import Paper from '@material-ui/core/Paper' 
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import DeleteIcon from '@material-ui/icons/Delete'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import AddPatient from './add-patient'
import Message from './message'
import { getUser as getAppUser, setUser } from './auth/app-user'
import { API, graphqlOperation } from 'aws-amplify'
import { getUser, getPatient }  from '../graphql/queries'
import { deleteUserMember } from '../graphql/mutations'
import { isTeleEligible } from '../utils/booking-api'

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 10,
    maxWidth: 600,
    [theme.breakpoints.up('sm')]: {
      margin: "auto",
      padding: 30
    }
  },
  center: {
    display: "flex",
    justifyContent: "center",
    marginTop: 20
  },
  iconColor: {
    color: theme.palette.primary.main
  }
}))

const Patient = ({
  pat,
  patientIndex, 
  setPatient, 
  setBpId,
  setPatStage,
  triggerMessage,
  setTriggerMessage,
  setPatIndexToBeDeleted
}) => {
  const { firstname, lastname, dob, id, bpPatientId } = pat
  const name = `${firstname} ${lastname}` 

  const bookAppointment = async() => {

    const teleEligible = await isTeleEligible(bpPatientId)
    //Save the selected patient
    const userInfo = {
      ...getAppUser(),
      patientName: name,
      patientId: id,
      bpPatientId: bpPatientId,
      patientIndex: patientIndex,
      teleEligible: teleEligible
    }
    setUser(userInfo)
    
    //Selected appointment slot already, go straight to booking. Otherwise send user to appointment browser.
    if (userInfo.appId)
      navigate("/book")
    else 
      navigate("/appointment-browser")
  }

  const removeExistingPatient = () => {
    setTriggerMessage(!triggerMessage)
    setPatIndexToBeDeleted(patientIndex)
  }

  return (
    <ListItem id={`patient-${patientIndex}`}>
      <ListItemText
        primary={name}
        secondary={dob}
      />
      <ListItemIcon>
        <IconButton edge="end" aria-label="delete" color="primary" onClick={removeExistingPatient}>
          <DeleteIcon />
        </IconButton>
      </ListItemIcon>
      <ListItemIcon>
        <IconButton edge="end" aria-label="book" color="primary" onClick={bookAppointment}>
          <ArrowForwardIosIcon />
        </IconButton>
      </ListItemIcon>
    </ListItem>
  )
}

const Legend = () => {
  const classes = useStyles()
  return (
    <>
      <ListItem>
        <ListItemIcon className={classes.iconColor}>
          <DeleteIcon />
        </ListItemIcon>      
        <ListItemText
          primary="Remove a patient from your management"
        />
      </ListItem>    
      <ListItem>
        <ListItemIcon className={classes.iconColor}>
          <ArrowForwardIosIcon />
        </ListItemIcon>      
        <ListItemText
          primary="Make an appointment for a patient"
        />
      </ListItem>
    </>             
  )
}

const Patients = ({patStage, setPatStage}) => {
  
  const [patients, setPatients] = useState([])
  const [patient, setPatient] = useState(null)
  const [triggerFetchPatients, setTriggerFetchPatients] = useState(false)
  const [triggerMessage, setTriggerMessage] = useState(false)
  const [patIndexToBeDeleted, setPatIndexToBeDeleted] = useState(null)
  const [bpId, setBpId] = useState(null)
  const classes = useStyles()

  useEffect(() => {
    const getPatientsByUser = async () => {
      const username = getAppUser().username
      try {
        const user = await API.graphql(graphqlOperation(getUser, {id: username}))
        const members = user.data.getUser.patients.items
        let patientIds = []
        members.forEach(member => {
          patientIds.push({
            patientId: member.memberID,
            userMemberId: member.id
          })
        })

        let pats = []
        let bpIds = []
        Promise.allSettled(patientIds.map(id => {
          return API.graphql(graphqlOperation(getPatient, {id: id.patientId}))
        }))
        .then((results) => {
          results.forEach(result => {
            if (result.status === 'fulfilled') {
              pats.push(result.value.data.getPatient)
              bpIds.push(result.value.data.getPatient.bpPatientId)
            }
          })

          pats.forEach((pat, index, pats) => {
            pats[index].userMemberId = patientIds[index].userMemberId
          })

          setPatients(pats)
        })

      } catch (err) {
        console.error('Amplify getUser error...: ', err)
      }
    }

    getPatientsByUser()

  }, [triggerFetchPatients, patStage])

  const doneAdd = () => {
    setTriggerFetchPatients(!triggerFetchPatients)
    setPatStage(0)
  }

  const addNewPatient = () => {
    setPatient(null)
    setPatStage(1)
  }

  const removeExistingPatient = async () => {
    try {
      const userMemberId = patients[patIndexToBeDeleted].userMemberId
      await API.graphql(graphqlOperation(deleteUserMember, {
        input: {
          id: userMemberId
        }}))
    } catch (err) {
      console.error('Amplify deleteUserMember error...: ', err)
      return
    }

    setTriggerFetchPatients(!triggerFetchPatients)
  }    

  return (
    <>
      {patStage === 0 && 
      <Paper className={classes.root} elevation={3}>
        <Typography variant="h6" align='center' color="secondary">Attention</Typography>
        <Typography variant="body1" align='left' gutterBottom>
          Online booking and patients are in separate systems. 
          You have to add patients to your online booking account in order to book appointments for a patient. 
          If a patient has been to our medical centre, you'll find the patient by clicking ADD A PATIENT FOR BOOKING. 
          If not, you can register a new patient first by clicking New Patient Form on the Find a Patient form.
        </Typography>
        <Typography variant="h6" align='center'>Actions for listed patients</Typography>
        <List>
          <Legend />
          {patients.map((pat, index) => (
            <Patient 
              key={pat.id} 
              pat={pat}
              patientIndex={index}
              setPatient={setPatient}
              setBpId={setBpId}
              setPatStage={setPatStage}
              triggerMessage={triggerMessage}
              setTriggerMessage={setTriggerMessage}
              setPatIndexToBeDeleted={setPatIndexToBeDeleted}
            />
          ))}
          <div className={classes.center} >
            <Button 
              color="primary"
              aria-label="Add a patient"
              startIcon={<PersonAddIcon />}
              onClick={addNewPatient}
            >
              Add a patient for booking
            </Button>
          </div>
        </List>
        <Message 
          triggerOpen={triggerMessage} 
          initOpen={false}
          message='Remove this patient from your booking management?'
          action="Confirm"
          cb={removeExistingPatient}
          disableClose={false}
        />                             
      </Paper>}
      {patStage === 1 && <AddPatient setPatient={setPatient} doneAdd={doneAdd}/>}
    </>
  )
}

export default memo(Patients)