import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper' 
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import DateFnsUtils from '@date-io/date-fns'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { patientSexCodes } from "../utils/bp-codes"
import { API, graphqlOperation } from 'aws-amplify'
import { createPatient, updatePatient, createUserMember } from '../graphql/mutations'
import { getUser, getPatient } from '../graphql/queries'
import moment from 'moment'
import { getUser as getAppUser} from './auth/app-user'
import { getPatientFromBP  } from '../utils/booking-api'
import { Link } from 'gatsby'
const newPatientForm = "https://register.aspiremedicalcentre.com.au/"

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 10,
    maxWidth: 600,
    [theme.breakpoints.up('sm')]: {
      margin: "auto",
      padding: 30
    }
  },
  center: {
    display: "flex",
    justifyContent: "space-around",
    marginTop: 20
  }
}))

const AddPatient = ({setPatient, doneAdd}) => {
  const classes = useStyles()
  const [firstName, setFirstName] = useState(null)
  const [lastName, setLastName] = useState(null)
  const [dOB, setDOB] = useState(null)
  const [gender, setGender] = useState(0)

  const handleAdd = async () => {
    const dob = moment(dOB).format("YYYY-MM-DD")
    const patientId = `${firstName.replace(/\s/g,'').replace(/\\-/g,'').toUpperCase()} ${lastName.replace(/\s/g,'').replace(/\\-/g,'').toUpperCase()} ${dob} ${gender}`
    const username = getAppUser().username
    const existingPatient = await API.graphql(graphqlOperation(getPatient, {id: patientId}))

    const bpId = await getPatientFromBP(lastName, firstName, dob)

    if (!existingPatient.data.getPatient) {
    
      if (bpId === null) {
        alert("This patient has never visited our medical centre. Please fill a new patient form first by clicking New Patient Form link below. Once the form is submitted, click Add again")
      } else {
        try {
          const result = await API.graphql(graphqlOperation(createPatient, {
            input: {
              id: patientId,
              title: 0, //not used
              firstname: firstName,
              lastname: lastName,
              dob: dob,
              gender: gender,
              bpPatientId: bpId
            }
          }))

          const pat = result.data.createPatient
          setPatient(`${pat.firstname} ${pat.lastname}`)

          API.graphql(graphqlOperation(createUserMember, {
            input: {
              userID: username,
              memberID: patientId
            }
          }))

          alert(`Found ${firstName} ${lastName} in our system.`)
          doneAdd()

        } catch (err) {
          console.error('Amplify createPatient error...: ', err)
        }
      } 
    } else {
      const pat = existingPatient.data.getPatient

      //Patient merge happened. Update BP patient ID
      if (bpId !== pat.bpPatientId)
      {
        const result = await API.graphql(graphqlOperation(updatePatient, {
          input: {
            id: patientId,
            title: 0, //not used
            firstname: firstName,
            lastname: lastName,
            dob: dob,
            gender: gender,
            bpPatientId: bpId
          }
        }))
      }

      setPatient(`${pat.firstname} ${pat.lastname}`)      
      //Check if this patient is already under management
      const user = await API.graphql(graphqlOperation(getUser, {id: username}))
      const members = user.data.getUser.patients.items
      const managedPatients = members.map(member => member.memberID)

      if (!managedPatients.includes(patientId)) {
        //Patient exists already, just add the new agent
        try {
          API.graphql(graphqlOperation(createUserMember, {
            input: {
              userID: username,
              memberID: patientId
            }
          }))
          alert(`Found ${firstName} ${lastName} in our system.`)
        } catch (err) {
          console.error('Amplify createUserMember error...: ', err)
        }
      } else {
        alert(`${firstName} ${lastName} is already under your management!`)
      }
      doneAdd()
    }
  }

  return (
    <Paper className={classes.root} elevation={3}>
      <Typography variant="h6" align='center'>Find a patient by name on Medicare card/passport and DOB</Typography>
      <TextField
        required
        margin="dense"
        label="First name"
        type="text"
        fullWidth
        value={firstName}
        onChange={(event) => setFirstName(event.target.value)}
      />
      <TextField
        required
        margin="dense"
        label="Last name"
        type="text"
        fullWidth
        value={lastName}
        onChange={(event) => setLastName(event.target.value)}
      />
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          required
          fullWidth
          margin="normal"
          id="date-picker-dialog"
          format="dd/MM/yyyy"
          label="DOB(dd/MM/yyyy)"
          value={dOB}
          onChange={setDOB}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
      </MuiPickersUtilsProvider>
      <FormControl fullWidth >
        <InputLabel htmlFor="gender-native-simple">Gender*</InputLabel>
        <Select
          native
          value={gender}
          onChange={event => setGender(parseInt(event.target.value))}
          inputProps={{
            name: 'gender',
            id: 'gender-native-simple',
          }}
        >
          {patientSexCodes.map((item, index) => <option key={index} value={item.code}>{item.label}</option>)}              
        </Select>
      </FormControl>
      <div className={classes.center}>
        <Button 
          variant="contained" 
          onClick={doneAdd} 
          color="primary"
          aria-label="cancel adding patient"
        >
          Cancel
        </Button>
        <Button 
          variant="contained" 
          onClick={handleAdd} 
          color="primary"
          aria-label="add patient"
          disabled={!(firstName && lastName && dOB && gender)}
        >
          Add
        </Button>
        <Link color='primary' href={newPatientForm} target="_blank">New Patient Form</Link>
      </div>
    </Paper>
  )
}

export default AddPatient